import {
  StyleParamType,
  createStylesParams,
  wixFontParam,
  wixColorParam,
} from '@wix/tpa-settings';

import {
  IChallengeDisplayStyles,
  IChallengeStyles,
} from './Settings/challengeStyles/challengeStyles.types';
import { challengeStyles as mobileStyles } from './Settings.mobile/challengeStyles';
import { fontValueFix } from '../ChallengesList/stylesParams';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { debug } from '../../utils/queryString';

const challengeDisplayStyles = createStylesParams<IChallengeDisplayStyles>({
  opgDisplayRewards: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDisplayRewardsForVisitor: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDisplayGroup: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDisplayHeaderDate: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDisplayHeaderPrice: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDisplayHeaderImage: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDisplayChallengeDuration: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDisplayChallengeSteps: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDisplayChallengeParticipants: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  opgDisplayOwner: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDisplayOneApp: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDisplayDivider: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDisplayTopJoinButton: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  opgDisplayPriceForFreePrograms: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgSocialSharingIsEnabled: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgSocialSharingForParticipantIsEnabled: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgJoinViaOneAppForParticipantIsEnabled: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  opgAgendaIsEnabled: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  opgAgendaShowTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgOverviewTabDisplay: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  /**
   * participant page sidebar layout
   */
  opgSidebarLayoutDisplayChallengeDate: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgSidebarLayoutDisplayChallengeDuration: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgSidebarLayoutDisplayChallengeSteps: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgSidebarLayoutDisplayChallengeParticipants: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  opgVisitorPageShowGroupImage: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgVisitorPageToggleAboutTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgVisitorPageToggleAboutJoinButtonTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  opgVisitorPageToggleGroupDiscussionTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgVisitorPageToggleInstructorTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgVisitorPageTogglePriceTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgVisitorPageTogglePriceJoinButtonTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  opgVisitorPageToggleShareTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgVisitorPageToggleStartDayTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgVisitorPageToggleStartDayJoinButtonTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  opgVisitorPageBodyEnableSidebar: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
});

export const customCssVars: CustomCssVarsFn = ({ styleParams }) => {
  const booleans = styleParams.booleans;
  const cssVars = Object.keys(booleans).reduce((result, key) => {
    if (booleans[key] === false) {
      result[key] = 'none';
    } else {
      result[key] = 'initial';
    }
    return result;
  }, {} as Record<string, string>);
  const hideStepsParticipants =
    booleans.opgDisplayChallengeSteps === false &&
    booleans.opgDisplayChallengeParticipants === false;
  if (hideStepsParticipants) {
    cssVars['opgDisplayChallengeDuration-divider'] = 'none';
  }
  if (hideStepsParticipants && booleans.opgDisplayChallengeDuration === false) {
    cssVars.opgDetailsList = 'none';
  }
  const opgSidebarLayoutSpace: number =
    styleParams.numbers.opgSidebarLayoutSpace || 0;

  cssVars.opgDisplaySidebarRightBorder =
    opgSidebarLayoutSpace === 0 ? 'none' : 'unset'; // the right border should be hidden if there is no space between section
  if (booleans.opgVisitorPageBodyEnableSidebar === false) {
    cssVars.visitorPageBodySidebarProportions = '0';
  }
  debug('customCssVars', cssVars);
  return cssVars;
};

const stylesParams = createStylesParams<IChallengeStyles>({
  visitorPageBodySidebarProportions: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  visitorPageBodySidebarVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 60,
  },
  visitorPageBodySidebarHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 60,
  },
  visitorHeaderPartsSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  visitorPagePaddingToHeader: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: () => 60,
  },
  visitorPageBodySectionsSpacing: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 60),
  },
  visitorPageBodySidebarGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  visitorPageVerticalPadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 80),
  },
  visitorPageHorizontalPadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 0),
  },
  visitorPageBodyVerticalPadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 60),
  },
  visitorPageBodyHorizontalPadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 60),
  },
  /**
   * sidebar styles
   */
  sidebarLayoutProgressBarProgressColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  sidebarLayoutSelectedStepColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.1),
  },
  sidebarLayoutOverviewDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutOverviewDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarLayoutOverviewTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 28,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutOverviewTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarLayoutBodyDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutBodyDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarLayoutBodyStepNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: isMobile ? 16 : 28,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutBodyStepNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarLayoutSidebarStepNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutSidebarStepNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarLayoutSidebarSectionNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutSidebarSectionNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarLayoutSidebarChallengeNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutSidebarChallengeNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarLayoutStepDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  sidebarLayoutDividerOpacityAndColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  sidebarLayoutBodyBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarLayoutSidebarBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarLayoutButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  sidebarLayoutButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarLayoutButtonBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  sidebarLayoutButtonBorderBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  sidebarLayoutButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  sidebarLayoutButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sidebarLayoutButtonHoverBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  sidebarLayoutButtonHoverBorderBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  sidebarLayoutButtonHoverFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarLayoutBodySidePaddingFullWidth: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 80;
    },
  },
  sidebarLayoutBodySidePadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 32;
    },
  },
  sidebarLayoutSidebarBodySpaceFromTop: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 32;
    },
  },
  sidebarLayoutSidebarSpaceBetweenSteps: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 20;
    },
  },
  /**
   * sidebar styles
   */
  sidebarLayoutSidebarSidePadding: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 20;
    },
  },
  sidebarLayoutSpace: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0;
    },
  },
  opgSidebarLayoutSpace: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0;
    },
  },
  sidebarLayoutProportionsFullWidth: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 30;
    },
  },
  sidebarLayoutProportions: {
    inheritDesktop: false,
    type: StyleParamType.Number,
    getDefaultValue() {
      return 30;
    },
  },
  /**
   * visitor styles
   */
  headerBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.1),
  },
  pageBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  challengeNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 40,
        }),
        textPresets,
      );
    },
  },
  challengeNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  dateFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  dateColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  priceFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  priceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 32,
        }),
        textPresets,
      );
    },
  },
  sectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  countersFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 20,
        }),
        textPresets,
      );
    },
  },
  countersColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  countersDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  countersDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  descriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  descriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buttonFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 18,
        }),
        textPresets,
      );
    },
  },
  buttonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  buttonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonHoverBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  buttonHoverBorderBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  buttonHoverFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  dividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  dividerLength: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  dividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  linksColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  mediaSectionHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 500,
  },
  contentDividersColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  contentDividersWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  agendaBoxPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  agendaBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  agendaBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  agendaBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  agendaDividersColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  agendaDividersWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  agendaIconColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  agendaSectionTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  agendaSectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  agendaStepNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  agendaStepNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  agendaStepCountFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 14,
        }),
        textPresets,
      );
    },
  },
  agendaStepCountColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  agendaLinkFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  agendaLinkColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.1),
  },
  sidebarDividersColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  sidebarDividersWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  sidebarSectionTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 32,
        }),
        textPresets,
      );
    },
  },
  sidebarSectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  videoOverlayColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0.1),
  },
  videoPlayButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.7),
  },
  ...challengeDisplayStyles,
  ...mobileStyles,
});

export default stylesParams;

export type IChallengeStylesKeys = keyof IChallengeStyles;
export type IChallengeStylesVars = `--${IChallengeStylesKeys}`;
